import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import React from "react";
import i18n from "i18next";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Container: React.FC<{ style?: React.CSSProperties }> = ({ children, style }) => (
  <div style={{ margin: "0 auto", maxWidth: "1200px", ...style }}>{children}</div>
);

const Footer: React.FC<{ t: TFunction }> = ({ t }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink: React.FC<SocialLinkProps> = ({ href, src }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      key={src}
      aria-label={src}
      style={{ margin: "0 10px" }}
    >
      <SvgIcon src={src} width="25px" height="25px" />
    </a>
  );

  return (
    <div style={{ backgroundColor: "#0a8131" }}>
      <Container style={{ padding: "30px 0" }}>
        <div className="wp-block-group is-layout-flex">
          <div className="wp-block-column">
            <figure className="wp-block-image size-full">
              <img
                decoding="async"
                width="187"
                height="46"
                src="https://trusted-tablets-online.com/wp-content/uploads/2024/05/Logo-white-TrustedTablets.png"
                alt=""
                className="wp-image-1976"
              />
            </figure>
          </div>
          <div className="wp-block-column">
            <div className="wp-block-group has-global-padding is-layout-constrained">
              <p className="has-text-align-right has-text-color">
                <strong>Trusted Tablets Online: Your Reliable Source for ED Medications</strong>
              </p>
              <p className="has-text-align-right has-text-color">
                Loc: 1605, Churchill Executive Tower, Burj Khalifa Area
              </p>
            </div>
          </div>
        </div>

        <div className="wp-block-columns is-layout-flex">
          <div className="wp-block-column">
            <p className="has-text-align-left has-text-color">
             Welcome to our specialized online pharmacy, where we address every aspect of your healthcare needs. We're here to serve as your trusted partner in health, providing a wide range of services tailored just for you. Our team of highly skilled professionals is dedicated to delivering personalized care to meet your specific requirements.
            </p>
          </div>
        </div>

        <hr className="wp-block-separator has-text-color" style={{ backgroundColor: "#0a8131" }} />

        <div className="wp-block-group has-global-padding is-layout-constrained">
          <div className="wp-block-columns is-layout-flex">
            <div className="wp-block-column">
              <p className="has-text-color">© 2010 – 2024 Trustedtablets Inc. All rights reserved.</p>
            </div>
            <div className="wp-block-column">
              <figure className="wp-block-image alignright size-full">
                <img
                  decoding="async"
                  width="196"
                  height="24"
                  src="https://trusted-tablets-online.com/wp-content/uploads/2024/05/footer-img.webp"
                  alt=""
                  className="wp-image-1974"
                />
              </figure>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withTranslation()(Footer);
import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }

    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #f9f9fb;
        overflow-x: hidden;
    }

    a:hover {
        color: #FF1E00;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #FF1E00 0px 0px 0px 1px;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #0a8131;
        line-height: 1.18;
    }

    h1 {
        font-size: 44px;  /* Same size as h6 */
        font-weight: 600;
    }

    h2 {
        font-size: 36px;  /* Slightly smaller than h1 */
    }

    h3 {
        font-size: 26px;  /* Slightly smaller than h2 */
    }

    h4 {
        font-size: 20px;  /* Slightly smaller than h3 */
    }

    h5 {
        font-size: 16px;   /* Slightly smaller than h4 */
    }

    h6 {
        font-size: 14 px;   /* Smallest size */
    }

    p {
        color: #000000;
        font-size: 18px;        
        line-height: 1.41;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #000000;

        :hover {
            color: #000000;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 100px !important;
    }
`;
